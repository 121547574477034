import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
export default {
  data: function data() {
    return {
      otherPage: this.pageInfo.pageNum,
      showCount: 7,
      start: 1,
      end: 0
    };
  },
  props: {
    pageInfo: {
      total: {
        type: Number
      },
      pageNum: {
        type: Number
      },
      pageSize: {
        type: Number
      }
    }
  },
  watch: {
    "pageInfo.pageNum": function pageInfoPageNum(newV) {
      this.otherPage = newV;
    }
  },
  computed: {
    totalPage: function totalPage() {
      var c = parseInt(this.pageInfo.total / this.pageInfo.pageSize);
      return this.pageInfo.total % this.pageInfo.pageSize > 0 ? c + 1 : c;
    },
    showPage: function showPage() {
      var preCount = parseInt(this.showCount / 2); //当前页前面显示个数
      var lastCount = this.showCount - preCount - 1; //当前页后面显示个数
      if (lastCount < 0) {
        lastCount = 0;
      }
      var last = parseInt(this.pageInfo.pageNum) + lastCount; //最后显示页数
      if (last > this.totalPage) {
        last = this.totalPage;
      }
      var start = parseInt(this.pageInfo.pageNum) - preCount; //第一个显示页数
      if (start < 1) {
        start = 1;
        if (7 < this.totalPage) {
          last = 7;
        } else {
          last = this.totalPage;
        }
      }
      var arr = [];
      for (var i = start; i <= last; i++) {
        arr.push(i);
      }
      this.start = start;
      this.end = last;
      return arr;
    }
  },
  methods: {
    /**
     * 上一页
     */
    getPre: function getPre() {
      if (this.pageInfo.pageNum > 1) {
        this.otherPage = this.pageInfo.pageNum - 1;
        // 子组件像父组件发消息，消息名称为getdata，参数为 this.pageInfo.pageNum - 1
        this.$emit('getdata', this.pageInfo.pageNum - 1);
      }
    },
    getLast: function getLast() {
      if (this.pageInfo.pageNum < this.totalPage) {
        this.otherPage = this.pageInfo.pageNum + 1;
        this.$emit('getdata', this.pageInfo.pageNum + 1);
      }
    },
    toPage: function toPage(page) {
      this.otherPage = page;
      this.$emit('getdata', this.otherPage);
    },
    getOtherPage: function getOtherPage() {
      if (this.otherPage > this.totalPage) {
        this.otherPage = this.totalPage;
      }
      this.toPage(this.otherPage);
    }
  }
};