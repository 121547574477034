var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.pageInfo.total > 0 ? _c('div', [_c('ul', {
    staticClass: "v2-g-flex-row"
  }, [_c('li', {
    staticClass: "pre btn",
    class: _vm.pageInfo.pageNum == 1 ? 'disabled' : '',
    on: {
      "click": _vm.getPre
    }
  }, [_vm._v("上一页")]), _vm.start > 1 ? _c('li', {
    staticClass: "more"
  }, [_vm._v("...")]) : _vm._e(), _vm._l(_vm.showPage, function (item) {
    return _c('li', {
      staticClass: "btn",
      class: _vm.pageInfo.pageNum == item ? 'sel' : '',
      on: {
        "click": function click($event) {
          return _vm.toPage(item);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  }), _vm.totalPage > _vm.end ? _c('li', {
    staticClass: "more"
  }, [_vm._v("...")]) : _vm._e(), _c('li', {
    staticClass: "last btn",
    on: {
      "click": _vm.getLast
    }
  }, [_vm._v("下一页")]), _c('li', {
    staticClass: "noborder"
  }, [_vm._v("共"), _c('span', [_vm._v(_vm._s(_vm.totalPage))]), _vm._v("页")]), _c('li', {
    staticClass: "noborder"
  }, [_vm._v("到第"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otherPage,
      expression: "otherPage"
    }],
    domProps: {
      "value": _vm.otherPage
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getOtherPage.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.otherPage = $event.target.value;
      }
    }
  }), _vm._v("页")]), _c('li', {
    staticClass: "noborer btn",
    on: {
      "click": _vm.getOtherPage
    }
  }, [_vm._v("确定")])], 2)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };